import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { isEqual } from 'lodash';

import { DefaultService } from '@/api-client';

// wrap in isEqual to avoid unnecessary re-renders of downstream atoms
export const meAtom = selectAtom(
  atom(async () => await DefaultService.me()),
  (me) => me,
  isEqual
);
