import { useAtomValue } from 'jotai';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';

import { meAtom } from '@/state/me';

const Wrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  padding: 2rem;
`;

export default function Home() {
  useAtomValue(meAtom); // login guard on 401 redirect
  const router = useRouter();

  useEffect(() => {
    router.push('/zapbook');
  }, [router]);

  return (
    <>
      <Head>
        <title>Welcome the the ZapAI</title>
        <meta name="description" content="Generated by ROBOTS." />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Wrapper>
        <h3>Welcome to ZapAI</h3>
        <p>Loading...</p>
      </Wrapper>
    </>
  );
}
